import React, { Component } from 'react';
import load from '../helpers/spreadsheet.js'
//import getData from '../helpers/getdata.js'
import config from '../config';


export default class PhoneList extends Component {

  state = {
    phones: [],
    error: null
    }

    onLoad = (data, error) => {
    if (data) {
      const phones = data.phones;
      this.setState({ phones });



      let jdata = JSON.stringify(data)

      localStorage.setItem(this.props.brand,jdata)


      //let uniquePhones = [...new Set(models)];
      //console.log(uniquePhones)
      //console.log(models)
      /*initiate the autocomplete function on the "myInput" element, and pass along the phone models array as possible autocomplete values:*/


    } else {
      this.setState({ error });
    }
  };




  initClient = () => {
  // 2. Initialize the JavaScript client library.
  window.gapi.client
    .init({
      apiKey: config.apiKey,
      // Your API key will be automatically added to the Discovery Document URLs.
      discoveryDocs: config.discoveryDocs
    })
    .then(() => {
    // 3. Initialize and make the API request.
    let brand = this.props.brand
    load(this.onLoad, brand)

  });
};






  componentDidMount() {
    if(localStorage.getItem(this.props.brand) == null){
      // 1. Load the JavaScript client library.
      window.gapi.load("client", this.initClient);
  } else {
    //console.log()
    const phones = JSON.parse(localStorage.getItem(this.props.brand)).phones
    this.setState({ phones });
    //console.log(phones)

  }



  }


  render() {
  const { phones, error } = this.state;
  if (error) {
    return <div>{this.state.error}</div>;
  }

  return(
    <>
    <div id="info" style={{position:'fixed',width:'100%',backgroundColor:'#111',cursor:'pointer',top:'0',left:'0',zIndex:'10',display:'none'}} onClick={()=>{document.getElementById('info').style.display = 'none'}}>
    <br />
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-info-circle" viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
    </svg>
    <br />
    <table>

    <tbody>

      {phones.map((phone, i) => (
        i===0 ? (
        <tr key={i}>
           <th>{phone.model}</th>
         </tr>
       ):(
           <tr key={i}>
              <td>{phone.model}</td>
            </tr>
         )
      ))

    }

    </tbody>
    </table>

    <p></p>
    <p>Kliknij w komunikat by zamknąć.</p><br /><br />
    </div>



    </>
  );
}


}
