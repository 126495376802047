import React, { Component } from 'react';
import loadBrands from '../helpers/brandsnames.js'
//import load from '../helpers/spreadsheet.js'
import config from '../config';
import PhoneList from './PhoneList';
import InfoList from './InfoList';
import SearchInput from './SearchInput';

export default class BrandsList extends Component {


  state = {
    brands: [],
    error: null
    }

    onLoad = (data, error) => {
    if (data) {
      const brands = data.brands;
      this.setState({ brands });
    } else {
      this.setState({ error });
    }
  };



  selectBrand = (e) =>{
    console.log(e.target.nextSibling);
    // change the data in the PhoneList table accordingly to the brand
    if(e.target.nextSibling.style.display === 'table'){
      e.target.nextSibling.style.display = 'none'
    } else {
      e.target.nextSibling.style.display = 'table'
    }
  }


  initClient = () => {
  // 2. Initialize the JavaScript client library.
  window.gapi.client
    .init({
      apiKey: config.apiKey,
      // Your API key will be automatically added to the Discovery Document URLs.
      discoveryDocs: config.discoveryDocs
    })
    .then(() => {
    // 3. Initialize and make the API request.
    loadBrands(this.onLoad);
  });
};



  componentDidMount() {
  // 1. Load the JavaScript client library.
  window.gapi.load("client", this.initClient);
  }


  render() {
  const { brands, error } = this.state;
  let infoBox = brands[0]
  console.log(infoBox)

  if (error) {
    return <div>{null}</div>;
  }
  return (
    <>
    <SearchInput />

    <div id="main-container">

      {brands.map((brand, i) => (
        brand.title!=='info'?(
        <div className="brand-btn" key={brand.title}>
        <button className="App-link" onClick={this.selectBrand} key={i}>
          {brand.title}&nbsp;
        </button>
        <PhoneList key={brand.title} id={brand.title} brand={brand.title} />
        </div>):(

        <InfoList key={brand.title} id={brand.title} brand={brand.title} />
        )

      ))}
    </div>
    </>
  );
}
}
