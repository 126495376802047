import React, { Component } from 'react';
import load from '../helpers/spreadsheet.js'
import getData from '../helpers/getdata.js'
import config from '../config';

let models = [];

export default class PhoneList extends Component {

  state = {
    phones: [],
    error: null
    }

    onLoad = (data, error) => {
    if (data) {
      const phones = data.phones;
      this.setState({ phones });



      let jdata = JSON.stringify(data)

      localStorage.setItem(this.props.brand,jdata)


      let allphones = document.getElementsByTagName('td');
      /*An array containing all the phones from the tables:*/

      for (let i=1;i<allphones.length;i++){
        models.push(String(allphones[i].classList))
      }
      let uniquePhones = [...new Set(models)];
      //console.log(uniquePhones)
      //console.log(models)
      /*initiate the autocomplete function on the "myInput" element, and pass along the phone models array as possible autocomplete values:*/
      autocomplete(document.getElementById("search-input"), uniquePhones);


    } else {
      this.setState({ error });
    }
  };




  initClient = () => {
  // 2. Initialize the JavaScript client library.
  window.gapi.client
    .init({
      apiKey: config.apiKey,
      // Your API key will be automatically added to the Discovery Document URLs.
      discoveryDocs: config.discoveryDocs
    })
    .then(() => {
    // 3. Initialize and make the API request.
    let brand = this.props.brand

      load(this.onLoad, brand)

  }).catch(err => alert(err));
};


  getDatas = (data, brand, error) => {
    // Check for changes in localStorage against google sheets
    // then overwrite them and add a temporary highlight for each item.

  if (data) {
    let a = JSON.parse(localStorage.getItem(brand)).phones
    let b = data
    // A comparer used to determine if two entries are equal.
    const isSameVal = (a, b) => a.model === b.model && a.price === b.price;

    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left, right, compareFunction) =>
      left.filter(leftValue =>
        !right.some(rightValue =>
          compareFunction(leftValue, rightValue)));

    //const onlyInA = onlyInLeft(a, b, isSameVal);
    const onlyInB = onlyInLeft(b, a, isSameVal);
    //console.log(b)
    const result = [...onlyInB];

    //console.log(result);
    if (result.length){
    result.forEach((item, i) => {
      if(document.getElementsByClassName(result[i].model)[1] !== undefined){
      document.getElementsByClassName(result[i].model)[1].innerHTML = result[i].price;
      document.getElementsByClassName(result[i].model)[1].style.color = 'red';
      document.getElementsByClassName(result[i].model)[1].style.fontWeight = '800';
      document.getElementsByClassName(result[i].model)[0].style.color = 'red';
      document.getElementsByClassName(result[i].model)[0].style.fontWeight = '800';
      b[result[i].model] = result[i].price;
    }
    });
    let update = JSON.stringify({"phones":b});
    localStorage.setItem(brand, update);
    }



} else {
  this.setState({ error });
}

};



  componentDidMount() {
    if(localStorage.getItem(this.props.brand) == null){
      // 1. Load the JavaScript client library.
      window.gapi.load("client", this.initClient);
  } else {
    //console.log()
    const phones = JSON.parse(localStorage.getItem(this.props.brand)).phones
    this.setState({ phones });
    //console.log(phones)


    let keys = Object.keys({ ...localStorage })
    //console.log(keys.length)
    keys.forEach((item, i) => {
      //console.log(JSON.parse(localStorage.getItem(item)).phones)
      //console.log(item)
      if(i===0){
        i=0;
      }
      else {
        i=i*20000;
      }

      if(!item.includes('firebase')){
        setTimeout(() => {
          getData(this.getDatas,item)
        }, i);
      }


    });

    /*An array containing all the phones from the tables:*/

    for (let i=1;i<phones.length;i++){
      models.push(phones[i])
    }
    let uniquePhones = [...new Set(models)];
    //console.log(uniquePhones)
    //console.log(models)
    /*initiate the autocomplete function on the "myInput" element, and pass along the phone models array as possible autocomplete values:*/
    autocomplete(document.getElementById("search-input"), uniquePhones);


  }



  }


  render() {
  const { phones, error } = this.state;
  if (error) {
    return <div>{null}</div>;
  }

  return(
    <>
    {this.props.brand === 'info' ? (
    <div id="info" style={{position:'fixed',width:'100%',backgroundColor:'#111',cursor:'pointer',top:'0',left:'0',zIndex:'10',display:'none'}} onClick={()=>{document.getElementById('info').style.display = 'none'}}>
    <br />
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-info-circle" viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
    </svg>
    <br />

    <p></p>
    <p>Kliknij w komunikat by zamknąć.</p><br /><br />
    </div>
  ): (
    <table className="phone-table" style={{display:'none'}}>

    <tbody>

      {phones.map((phone, i) => (
        i===0 ? (
        <tr key={i}>
           <th>{phone.model}</th>
           <th>{phone.price}</th>
         </tr>
       ):(
           <tr key={i}>
              <td className={phone.model}>{phone.model}</td>
              <td className={phone.model}>{phone.price}</td>
            </tr>
         )
      ))

    }

    </tbody>
    </table>
  )}
    </>
  );
}


}




function autocomplete(inp, arr) {
  /*the autocomplete function takes two arguments,
  the text field element and an array of possible autocompleted values:*/
  var currentFocus;
  /*execute a function when someone writes in the text field:*/
  inp.addEventListener("input", function(e) {
      var a, b, i, val = this.value;
      /*close any already open lists of autocompleted values*/
      closeAllLists();
      if (!val) { return false;}
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", this.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      this.parentNode.appendChild(a);
      /*for each item in the array...*/
      for (i = 0; i < arr.length; i++) {
        /*check if the item starts with the same letters as the text field value:*/
        if (arr[i].model.substr(0, val.length).toUpperCase() === val.toUpperCase()) {
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          /*make the matching letters bold:*/
          b.innerHTML = "<strong>" + arr[i].model.substr(0, val.length) + "</strong>";
          b.innerHTML += arr[i].model.substr(val.length) +" - " + arr[i].price;
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' value='" + arr[i].model + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
          b.addEventListener("click", function(e) {
              /*insert the value for the autocomplete text field:*/
              inp.value = this.getElementsByTagName("input")[0].value;
              inp.click()
              /*close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
              closeAllLists();
          });
          a.appendChild(b);
        }
      }
  });
  /*execute a function presses a key on the keyboard:*/
  inp.addEventListener("keydown", function(e) {
      var x = document.getElementById(this.id + "autocomplete-list");
      if (x) x = x.getElementsByTagName("div");
      if (e.keyCode === 40) {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode === 38) { //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode === 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click();
        }
      }
  });
  function addActive(x) {
    /*a function to classify an item as "active":*/
    if (!x) return false;
    /*start by removing the "active" class on all items:*/
    removeActive(x);
    if (currentFocus >= x.length) currentFocus = 0;
    if (currentFocus < 0) currentFocus = (x.length - 1);
    /*add class "autocomplete-active":*/
    x[currentFocus].classList.add("autocomplete-active");
  }
  function removeActive(x) {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove("autocomplete-active");
    }
  }
  function closeAllLists(elmnt) {
    /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      if (elmnt !== x[i] && elmnt !== inp) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }
  /*execute a function when someone clicks in the document:*/
  document.addEventListener("click", function (e) {
      closeAllLists(e.target);
  });
}
