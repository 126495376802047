import config from "../config";
/**
 * Load the phones from the spreadsheet
 * Get the right values from it and assign.
 */


export default function getData(callback, brand) {
  
  window.gapi.client
    .init({
      apiKey: config.apiKey,
      // Your API key will be automatically added to the Discovery Document URLs.
      discoveryDocs: config.discoveryDocs
    })
    .then(() => {
    // 3. Initialize and make the API request.

    window.gapi.client.load("sheets", "v4", () => {

        window.gapi.client.sheets.spreadsheets.values
          .get({
            spreadsheetId: config.spreadsheetId,
            range: brand+"!A1:B",
          })
          .then(
            response => {
              const data = response.result.values;
              console.log(response);
                if(data !== undefined){

                try{
                const newphones = data.map(phone => ({
                  model: phone[0],
                  price: phone[1],
                })) || [];

                callback(
                  newphones,
                  brand
                );
              } catch(error){
                console.log(error)
              }
              }
            },
            response => {
              callback(false, response.result.error);
            }
          ).catch(err => alert(err))


    });

  })

}




//[{model: "Model:", price: "Cena:"}, {model: "A33 black ", price: "60,00 zł"}, {model: "S20 ultra gray 1x 100", price: "90,00 zł"}, {model: "A12 black 3x 70 szt.", price: "60,00 zł"}, {model: "Note 10plus 2x black 100", price: "90,00 zł"}, {model: "S21 plus 2x  white, black 100 szt", price: "90,00 zł"}, {model: "S8 plus blue 1x 50", price: "60,00 zł"}, {model: "S9 pink 1x 50", price: "60,00 zł"}, {model: "S9 plus black 1x 50", price: "60,00 zł"}, {model: "S20 gray 1x 100", price: "90,00 zł"}]
