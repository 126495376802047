import React, { Component } from 'react';

export default class SearchInput extends Component {


  searchTables = (e) => {
    console.log(e.target.value)
    //let cells = document.getElementsByTagName('td');
    let tables = document.getElementsByTagName('table');
    //console.log(cells)
    console.log(tables);

    for(let i=0;i<tables.length;i++){


      //console.log(tables[i].querySelectorAll('td'));

          for (let x=0;x<tables[i].querySelectorAll('td').length;x++){
            //console.log(String(tables[i].querySelectorAll('td')[x].classList).toLowerCase().includes(document.getElementsByTagName("input")[0].value.toLowerCase()))
            if(String(tables[i].querySelectorAll('td')[x].classList).toLowerCase().includes(document.getElementsByTagName("input")[0].value.toLowerCase())){
              tables[i].querySelectorAll('td')[x].parentElement.style.display = 'table-row';
            } else {
              tables[i].querySelectorAll('td')[x].parentElement.style.display = 'none';
            }
          }

          for (let x=0;x<tables[i].querySelectorAll('td').length;x++){
            if(tables[i].querySelectorAll('td')[x].parentElement.style.display === 'none'){
              tables[i].style.display='none';
            }
          }

          for (let x=0;x<tables[i].querySelectorAll('td').length;x++){
            if(tables[i].querySelectorAll('td')[x].parentElement.style.display ==='table-row'){
              tables[i].style.display='table';
            }
          }




    }


  }





render(){
  return (
  <>
  <div className="search-container">
  <label><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
  </svg>

  <p>Wyszukaj w tabelach:</p>
  <div className="autocomplete" style={{marginLeft:'20px'}}>
    <input id="search-input"  onInput={this.searchTables} onClick={this.searchTables} onChange={this.searchTables} type="text" name="search" placeholder="iphone X" />
  </div>
  </label>
  </div>
</>
)}
}
