import React, { useEffect } from 'react';
import logo from './logo.png';
import mios from './images/ios.png';
import './App.css';
import BrandsList from './components/BrandsList';
import { initializeApp } from "firebase/app";
import { getDatabase, child, ref, set, get } from "firebase/database";
import { firebaseConfig } from "./firebase.config";





const ios = () => {
    if (typeof window === `undefined` || typeof navigator === `undefined`) return false;

    return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor);
};

const isInStandaloneMode = () => {
  return (window.navigator.standalone === true) || (window.matchMedia('(display-mode: standalone)').matches);
}


initializeApp(firebaseConfig);


function App() {
  useEffect( () => {

  if((ios() === false)||(isInStandaloneMode() === true)){
    checkSub()
  } else if ((ios() === true)&&(isInStandaloneMode() === false)) {
    document.getElementById('subscription').style.display = 'none';
    document.getElementById('instruction').style.display = 'flex';
  }
  console.log('is ios: '+ ios(), 'is pwa: ' + isInStandaloneMode())
  }, [] );
  return (
    <div className="App">
    <div className="navbar">
    <div style={{display:'flex',flexWrap:'wrap',margin:'10px',justifyContent:'space-between'}}>
    <button id="reload" className="App-link" style={{fontSize:'.7em',margin:'10px'}} onClick={()=>{window.location.reload()}}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
    </svg>
    </button>
    <button className="App-link" style={{fontSize:'.7em',margin:'10px'}} onClick={showInfo}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
    </svg>
    </button>
    <button id="subscription" className="App-link" style={{fontSize:'.7em',margin:'10px'}} onClick={subscribe}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bell" viewBox="0 0 16 16">
      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
    </svg>
    </button>
    <button id="unsubscription" className="App-link" style={{fontSize:'.7em',margin:'10px',display:'none'}} onClick={unsubscribe}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bell-slash" viewBox="0 0 16 16">
      <path d="M5.164 14H15c-.299-.199-.557-.553-.78-1-.9-1.8-1.22-5.12-1.22-6 0-.264-.02-.523-.06-.776l-.938.938c.02.708.157 2.154.457 3.58.161.767.377 1.566.663 2.258H6.164l-1 1zm5.581-9.91a3.986 3.986 0 0 0-1.948-1.01L8 2.917l-.797.161A4.002 4.002 0 0 0 4 7c0 .628-.134 2.197-.459 3.742-.05.238-.105.479-.166.718l-1.653 1.653c.02-.037.04-.074.059-.113C2.679 11.2 3 7.88 3 7c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0c.942.19 1.788.645 2.457 1.284l-.707.707zM10 15a2 2 0 1 1-4 0h4zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75L.625 15.625z"/>
    </svg>
    </button>
    <button id="instruction" className="App-link" style={{fontSize:'.7em',margin:'10px',display:'none'}} onClick={showbox}>Instrukcje dla ios
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bell-slash" viewBox="0 0 16 16">
      <path d="M5.164 14H15c-.299-.199-.557-.553-.78-1-.9-1.8-1.22-5.12-1.22-6 0-.264-.02-.523-.06-.776l-.938.938c.02.708.157 2.154.457 3.58.161.767.377 1.566.663 2.258H6.164l-1 1zm5.581-9.91a3.986 3.986 0 0 0-1.948-1.01L8 2.917l-.797.161A4.002 4.002 0 0 0 4 7c0 .628-.134 2.197-.459 3.742-.05.238-.105.479-.166.718l-1.653 1.653c.02-.037.04-.074.059-.113C2.679 11.2 3 7.88 3 7c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0c.942.19 1.788.645 2.457 1.284l-.707.707zM10 15a2 2 0 1 1-4 0h4zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75L.625 15.625z"/>
    </svg>
    </button>
    </div>
    </div>
      <header className="App-header" style={{display:'block',textAlign:'center'}}>



        <div id="inst" style={{position:'fixed',width:'100%',backgroundColor:'rgb(70,48,236)',cursor:'pointer',top:'0',zIndex:'10',display:'none'}} onClick={()=>{document.getElementById('inst').style.display = 'none'}}>
        <br />
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-bell-fill" viewBox="0 0 16 16">
          <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
        </svg>
        <br /><p>Aby włączyć powiadomienia na ios dodaj aplikację do ekranu głównego.</p>
        <img src={mios} alt="instructions"  width="100%" style={{maxWidth:'500px',margin:'auto'}} height="auto" />
        <p>Kliknij w obrazek by zamknąć.</p><br /><br />
        </div>


      <div style={{padding:'10px'}}>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        <a href="/" className="company-link">serwistelefonow.com.pl</a>
        </p>
        <h1>
          Cennik napraw<br /> i części
        </h1>
        <br />

      </div>



      </header>

      <BrandsList />

      <footer>
      <p>
      <a style={{color:'white',fontSize:'12px'}} href="https://swiezastrona.pl">©{new Date().getFullYear()} Świeża Strona</a>
      </p>
      </footer>

    </div>
  );
}

export default App;


async function subscribe(){
  const db = getDatabase();
  let sw = await navigator.serviceWorker.ready;
  let push = await sw.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: 'BGUrlFKvKZV9oly1KppQFmRLkBO0PUTzaEwsOaZ0r2l7ZfzO3l1PnCNyG4F8f5C5prpr1Xj7TmCDcpodKI87z9I'
  })
  //console.log(JSON.stringify(push))
  let updated = JSON.stringify(push)


  get(child(ref(getDatabase()), `Subscribers/client`)).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val().includes(updated));
      if(snapshot.val().includes(updated) !== true){
      set(ref(db, 'Subscribers/'), {
        client: snapshot.val()+','+updated,
      });
      console.log('sucessfully subscribed...');
      document.getElementById('subscription').style.display = 'none';
      document.getElementById('unsubscription').style.display = 'flex';
    } else {
      console.log('already subscribed...');
      document.getElementById('subscription').style.display = 'flex';
      document.getElementById('unsubscription').style.display = 'none';
    }



    } else {
      console.log("No data available");
      set(ref(db, 'Subscribers/'), {
        client: updated,
      });
    }
  }).catch((error) => {
    console.error(error);
  });
}



async function unsubscribe(){
  const db = getDatabase();
  let sw = await navigator.serviceWorker.ready;
  let push = await sw.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: 'BGUrlFKvKZV9oly1KppQFmRLkBO0PUTzaEwsOaZ0r2l7ZfzO3l1PnCNyG4F8f5C5prpr1Xj7TmCDcpodKI87z9I'
  })
  //console.log(JSON.stringify(push))
  let updated = JSON.stringify(push)


  get(child(ref(getDatabase()), `Subscribers/client`)).then((snapshot) => {
    if (snapshot.exists()) {
      //console.log(snapshot.val());
      console.log(snapshot.val().includes(updated));
      if(snapshot.val().includes(updated) === true){
        set(ref(db, 'Subscribers/'), {
          client: snapshot.val().replace(','+updated,''),
        });
          console.log('unsubscribed...');
          document.getElementById('subscription').style.display = 'flex';
          document.getElementById('unsubscription').style.display = 'none';
    } else {
      console.log('not subscribed...');
      document.getElementById('unsubscription').style.display = 'none';
    }



    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  });
}


function showbox(){
  document.getElementById('inst').style.display = 'block';
}

function showInfo(){
  document.getElementById('info').style.display = 'block';
}


async function checkSub(){
  //const db = getDatabase();
  let sw = await navigator.serviceWorker.ready;
  let push = await sw.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: 'BGUrlFKvKZV9oly1KppQFmRLkBO0PUTzaEwsOaZ0r2l7ZfzO3l1PnCNyG4F8f5C5prpr1Xj7TmCDcpodKI87z9I'
  })
  //console.log(JSON.stringify(push))
  let updated = JSON.stringify(push)


  get(child(ref(getDatabase()), `Subscribers/client`)).then((snapshot) => {

      //console.log(snapshot.val());
      console.log(snapshot.val().includes(updated));
      if(snapshot.val().includes(updated) === true){
      console.log('already subscribed...');
      document.getElementById('subscription').style.display = 'none';
      document.getElementById('unsubscription').style.display = 'flex';
    } else {
      console.log('not subscribed...');
      document.getElementById('unsubscription').style.display = 'none';
      document.getElementById('subscription').style.display = 'flex';
      Notification.requestPermission().then(result => {
        if (result === 'granted') {
          console.log('Notification permission granted.');
        } else {
          alert('Zezwól na powiadomienia aby otrzymywać informacje o aktualizacjach.');
        }
      });
    }


  }).catch((error) => {
    console.error(error);
  });
}
