import config from "../config";
/**
 * Load the brands from the spreadsheet
 * Get the right values from it and assign.
 */
 export default function loadBrands(callback) {
   window.gapi.client.load("sheets", "v4", () => {
     window.gapi.client.sheets.spreadsheets
       .get({
         spreadsheetId: config.spreadsheetId,
         fields: "sheets/properties/title"
       })
       .then(
         response => {
           //console.log(response.result.sheets);
           const data = response.result.sheets;
           const brands = data.map(brand => ({
             title: brand.properties.title,
           })) || [];
           //console.log(brands);
           callback({
             brands
           });

         },
         response => {
           callback(false, response.result.error);
         }
       ).catch(err => alert(err));
   });
 }
