import config from "../config";
/**
 * Load the phones from the spreadsheet
 * Get the right values from it and assign.
 */


export default function load(callback, brand) {
  window.gapi.client.load("sheets", "v4", () => {
    window.gapi.client.sheets.spreadsheets.values
      .get({
        spreadsheetId: config.spreadsheetId,
        range: brand+"!A1:B",
      })
      .then(
        response => {
          const data = response.result.values;
            if(data !== undefined){
            const phones = data.map(phone => ({
              model: phone[0],
              price: phone[1],
            })) || [];
            //console.log(response.result.values);
            callback({
              phones
            });
          }
        },
        response => {
          callback(false, response.result.error);
        }
      ).catch(err => alert(err));

  });
}
